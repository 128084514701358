import React from 'react';

const CardPolicyContent = ({ theme }) => {
  return (
    <section className='blog-pg single' style={{ padding: `50px 0` }}>
      <div className='container'>
        <h1 align='center'>ĐIỀU KHOẢN ĐĂNG KÝ THẺ VIP UNI</h1>
        <p align='center'>
          Được thông tin đến khách hàng trên App UniService khi đăng ký thẻ lần
          đầu tiên
        </p>
        <p style={{ color: '#000', fontWeight: 400, lineHeight: 3 }}>
          <strong>I. Thông tin về Chương trình Thẻ VIP Uni</strong>
        </p>
        <p style={{ color: '#000', fontWeight: 400, lineHeight: 3 }}>
          <strong>1. Mục đích của Điều khoản đăng ký Thẻ VIP:</strong>
        </p>
        <ul type='disc'>
          <li>
            Quy định quyền lợi và trách nhiệm của thành viên đối với Chương
            trình Thẻ VIP UniService (gọi tắt là "Chương trình").
          </li>
          <li>
            Nâng cao trải nghiệm khách hàng, xây dựng cộng đồng khách hàng thân
            thiết, thu hút sự tham gia của khách hàng đối với Chương trình và hệ
            sinh thái UniService.
          </li>
        </ul>
        <p style={{ color: '#000', fontWeight: 400, lineHeight: 3 }}>
          <strong>2. Phạm vi áp dụng của Chương trình:</strong>
        </p>
        <ul type='disc'>
          <li>
            Chương trình áp dụng cho tất cả các khách hàng tham gia đăng ký và
            sử dụng Thẻ VIP UniService.
          </li>
          <li>
            Chương trình áp dụng tại tất cả các cửa hàng UniService trên toàn
            quốc.
          </li>
        </ul>
        <p style={{ color: '#000', fontWeight: 400, lineHeight: 3 }}>
          <strong>3. Định nghĩa:</strong>
        </p>
        <ul type='disc'>
          <li>
            <strong>Thẻ VIP UniService:</strong> Thẻ do UniService cấp cho khách
            hàng tham gia Chương trình.
          </li>
          <li>
            <strong>Tài khoản thành viên VIP:</strong> Tài khoản được tạo lập
            cho mỗi khách hàng tham gia Chương trình, lưu trữ thông tin cá nhân
            và số điểm tích lũy.
          </li>
          <li>
            <strong>Điểm tích lũy:</strong> Điểm được tích lũy cho mỗi giao dịch
            sử dụng dịch vụ tại UniService.
          </li>
          <li>
            <strong>Ưu đãi:</strong> Các quyền lợi dành cho thành viên bao gồm
            nạp tiền, khuyến mãi, giảm giá,…
          </li>
        </ul>
        <p style={{ color: '#000', fontWeight: 400, lineHeight: 3 }}>
          <strong>II. Điều khoản tham gia Chương trình:</strong>
        </p>
        <p style={{ color: '#000', fontWeight: 400, lineHeight: 3 }}>
          <strong>1. Điều kiện tham gia:</strong>
        </p>
        <ul type='disc'>
          <li>
            Khách hàng đủ 18 tuổi trở lên, có khả năng hành vi dân sự đầy đủ.
          </li>
          <li>
            Khách hàng tải app UniService và cung cấp đầy đủ thông tin cá nhân
            chính xác khi đăng ký.
          </li>
          <li>
            Khách hàng đồng ý với tất cả các điều khoản và quy định của Chương
            trình.
          </li>
        </ul>
        <p style={{ color: '#000', fontWeight: 400, lineHeight: 3 }}>
          <strong>2. Quy trình đăng ký:</strong>
        </p>
        <ul type='disc'>
          <li>
            Khách hàng có thể đăng ký tham gia Chương trình miễn phí tại các cửa
            hàng UniService hoặc thông qua App Uniservice.
          </li>
          <li>
            Sau khi có tài khoản trên App Uniservice, khách hàng vào “Thẻ Uni”
            nhập thông tin đăng ký, mỗi tài khoản được đăng ký tối đa 5 Thẻ.
          </li>
          <li>
            Sau khi đăng ký thành công, khách hàng sẽ được thông báo cấp Thẻ VIP
            kèm thời gian, địa điểm nhận thẻ.
          </li>
        </ul>
        <p style={{ color: '#000', fontWeight: 400, lineHeight: 3 }}>
          <strong>III. Quyền lợi của thành viên:</strong>
        </p>
        <p style={{ color: '#000', fontWeight: 400, lineHeight: 1.5 }}>
          <strong>1.</strong>Tích lũy điểm cho mỗi giao dịch sử dụng dịch vụ tại
          UniService. Điểm tích lũy trên Thẻ VIP có giá trị sử dụng như tiền
          mặt, có thể sử dụng điểm để đổi quà, thanh toán hoặc nhận ưu đãi tại
          các cửa hàng UniService.
        </p>
        <p style={{ color: '#000', fontWeight: 400, lineHeight: 3 }}>
          <strong>2.</strong>Thành viên được hưởng các ưu đãi sau:
        </p>
        <ul type='disc'>
          <ul type='circle'>
            <li>Ưu đãi tặng điểm Uni cho lần nạp đầu tiên</li>
            <li>
              Được tri ân hàng tháng, và được hoàn 1% tổng số tiền thanh toán
              hằng tháng.
            </li>
            <li>Giảm 20 % cho tuần sinh nhật của chủ thẻ (1 giao dịch)</li>
            <li>Ưu tiên thông báo các chương trình khuyến mãi độc quyền.</li>
            <li>Ưu tiên tiếp cận các dịch vụ và sản phẩm mới nhất.</li>
          </ul>
        </ul>
        <p style={{ color: '#000', fontWeight: 400, lineHeight: 3 }}>
          <strong>3.</strong> Tham gia các hoạt động dành riêng cho thành viên.
        </p>
        <p style={{ color: '#000', fontWeight: 400, lineHeight: 3 }}>
          <strong>IV. Trách nhiệm của thành viên:</strong>
        </p>
        <p style={{ color: '#000', fontWeight: 400, lineHeight: 3 }}>
          <strong>1. Sử dụng Thẻ VIP đúng mục đích:</strong>
        </p>
        <ul type='disc'>
          <li>
            Sử dụng Thẻ VIP để thanh toán cho các giao dịch dịch vụ tại
            UniService.
          </li>
          <li>Giữ gìn Thẻ VIP cẩn thận, không để mất hoặc hư hỏng.</li>
          <li>Cung cấp Thẻ VIP khi thanh toán để được hưởng ưu đãi.</li>
        </ul>
        <p style={{ color: '#000', fontWeight: 400, lineHeight: 3 }}>
          <strong>2. Cập nhật thông tin cá nhân:</strong>
        </p>
        <ul type='disc'>
          <li>Cập nhật thông tin cá nhân khi có thay đổi.</li>
          <li>Chịu trách nhiệm về tính chính xác của thông tin cá nhân.</li>
        </ul>
        <p style={{ color: '#000', fontWeight: 400, lineHeight: 3 }}>
          <strong>3. Tuân thủ quy định:</strong>
        </p>
        <ul type='disc'>
          <li>Tuân thủ tất cả các điều khoản và quy định của Chương trình.</li>
          <li>Sử dụng dịch vụ tại UniService một cách văn minh, lịch sự.</li>
        </ul>
        <p style={{ color: '#000', fontWeight: 400, lineHeight: 3 }}>
          <strong>V. Quy định về quản lý Thẻ VIP:</strong>
        </p>
        <p style={{ color: '#000', fontWeight: 400, lineHeight: 3 }}>
          <strong>1. Hủy thẻ:</strong>
        </p>
        <ul type='disc'>
          <li>
            Khách hàng có thể hủy Thẻ VIP bất kỳ lúc nào bằng cách liên hệ cho
            UniService hoặc thông qua App UniService
          </li>
          <li>
            UniService có quyền hủy Thẻ VIP nếu khách hàng vi phạm các điều
            khoản và quy định của Chương trình.
          </li>
        </ul>
        <p style={{ color: '#000', fontWeight: 400, lineHeight: 3 }}>
          <strong>2. Thay đổi điều khoản:</strong>
        </p>
        <ul type='disc'>
          <li>
            UniService có quyền thay đổi các điều khoản và quy định của Chương
            trình mà không cần thông báo trước.
          </li>
          <li>Các thay đổi sẽ có hiệu lực sau 30 ngày kể từ ngày thông báo.</li>
        </ul>
        <p style={{ color: '#000', fontWeight: 400, lineHeight: 3 }}>
          <strong>3. Giải quyết tranh chấp:</strong>
        </p>
        <ul type='disc'>
          <li>
            Mọi tranh chấp liên quan đến Chương trình sẽ được giải quyết theo
            quy định của pháp luật Việt Nam.
          </li>
        </ul>
        <p style={{ color: '#000', fontWeight: 400, lineHeight: 3 }}>
          <strong>VI. Liên hệ:</strong>
        </p>
        <p style={{ color: '#000', fontWeight: 400, lineHeight: 3 }}>
          Để biết thêm thông tin chi tiết, vui lòng liên hệ với hotline bộ phận
          chăm sóc khách hàng qua số điện thoại: 0931309259.
        </p>
      </div>
    </section>
  );
};

export default CardPolicyContent;

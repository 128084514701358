import React from 'react';
import CardPolicyContent from 'components/Policy/CardPolicyContent';
import LightTheme from 'layouts/Light';

const CardPrivacyPolicy = () => {
  return (
    <LightTheme>
      <CardPolicyContent />
    </LightTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>UniService.Vn - Membership Card Privacy Policy</title>
    </>
  );
};

export default CardPrivacyPolicy;
